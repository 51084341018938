.sidebar {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Playfair Display", serif;
    font-size: 1.5em;
    padding-left: 5%;
    margin-top: 2%;
}

.category {
    margin: 5px 0;
    cursor: pointer;
    transition: transform 0.3s ease, font-weight 0.3s ease;
}

.category:hover {
    transform: scale(1.4);
    font-weight: 600;
}

.category.selected {
    transform: scale(1.4);
    font-weight: 600;
}