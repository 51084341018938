.hero {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
}

.hero-image {
    position: relative;
    width: 175px;
    height: 175px;
    border-radius: 50%;
    object-fit: cover;
    object-position: right;
}

.hero-name {
    position: relative;
    color: white;
    font-size: 3.5em;
    text-align: center;
    margin-top: 20px;
    font-family: "Playfair Display", serif;
}