.video-grid {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-bottom: 10vh;
    margin-top: 5vh;
}

.video-grid > * {
    aspect-ratio: 16 / 9;
    transition: transform 0.3s ease;
}