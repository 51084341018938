@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.video {
    animation: fade-in 1s ease;
}

.video.fade-out {
    animation: fade-out 1s ease;
}